<template>
  <div>
    <el-row class="m-0" :gutter="20">
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline" >Добавить</el-button>
      </el-col>
      <el-col :span="3">
      <el-button @click="handleDownLoad" type="text"
                  :loading="loading"
                   icon="el-icon-download">Выгрузить
        </el-button>
      </el-col>
    <el-col :span="12">
      <pagination
        style="text-align: center"
        :total-count="totalCount"
        :page-size.sync="pageSize"
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-col>
    </el-row>
    <div>
      <div>
        <el-button @click="toggleNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
        <span class="total_items">Всего: {{this.techReasonsCount}}</span>
      </div>
      <el-table
        ref="table"
        v-loading="loading"
        style="width: 100%"
        row-key="id"
        :height="tableHeight"
        :data="technicalReasons"
        :default-sort = "{prop: 'name', order: 'ascending'}"
        @sort-change="handleSortChange"
      >
        <el-table-column v-if="userCanEdit" label="" width="70" align="right">
          <template slot-scope="scope">
              <el-button v-if="!scope.row.parentId" type="text" icon="el-icon-edit"
                        @click="handleEdit(scope.$index, scope.row)"></el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="Наименование"
          sortable="custom"
          >
          <template slot-scope="scope">
            <div style="display:inline-flex;">
                <el-button v-if="scope.row.parentId && userCanEdit" style="margin-right:.8rem" type="text" icon="el-icon-edit"
                          @click="handleEdit(scope.$index, scope.row)"></el-button>
              <div>{{scope.row.name}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="code"
          label="Код"
          sortable="code"
          min-width="30">
        </el-table-column>
        <el-table-column
          prop="actual"
          label="Признак актуальности"
          sortable="custom"
          width="220"
          header-align="center"
          align="center"
          :formatter="boolRenderer">
        </el-table-column>
        <el-table-column v-if="userCanEdit" label="" width="50">
            <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-delete"
                            @click="handleDelete(scope.$index, scope.row)"></el-button>
            </template>
          </el-table-column>
      </el-table>
    </div>
    <technical-reason-modal @on-update="getTechnicalReasons()" @on-create="getTechnicalReasons()" v-model="dialogVisible" :technicalReason="dialogTechnicalReason"></technical-reason-modal>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';
import technicalReasonsApi from '@/api/nsi/technicalReasons';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import tableResize from '@/mixins/tableResize.js';
import xlsUpDownHelper from '@/mixins/xlsUpDownHelper';
import TechnicalReasonModal from './TechnicalReasonModal';

export default {
  name: 'TechnicalReasonsTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
  },
  mixins: [formatters, tableResize, xlsUpDownHelper],
  components: { pagination, TechnicalReasonModal },
  data() {
    return {
      loading: false,
      technicalReasons: [],
      pageNumber: 1,
      pageSize: 50,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      dialogTechnicalReason: {},
      techReasonsCount: 0
    };
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiLocalEdit,NsiFullAccess');
    },
  },
  watch: {
    filterModel: {
      handler() {
        this.getTechnicalReasons();
      },
      deep: true
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    });
    await this.getTechnicalReasons();
    this.tableHeight = this.getTableHeight();
  },
  methods: {
    async handleDownLoad() {
      this.loading = true;
      const { table } = this.$refs;
      const tableColumns = table.store.states.columns;
      const res = await technicalReasonsApi.getTechnicalReasonsWithPagination(
          this.pageNumber,
          this.totalCount,
          this.filterModel.nameFilter,
          this.sortField,
          this.sortDesc
      );
      if (res.data) {
        await this.exportToExcel('Технические причины', res.data.items, tableColumns);
      }
      this.loading = false;
    },
    async getTechnicalReasons() {
      this.loading = true;
      const res = await technicalReasonsApi.getTechnicalReasonsWithPagination(
          this.pageNumber,
          this.pageSize,
          this.filterModel.nameFilter,
          this.sortField,
          this.sortDesc
      );
      if (res.data) {
        this.technicalReasons = res.data.items;
        this.totalCount = res.data.totalCount;
        this.techReasonsCount = res.data.treeItemsCount;
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getTechnicalReasons();
    },
    async handleCurrentChange() {
      await this.getTechnicalReasons();
    },
    async handleSortChange(event) {
        this.sortField = event.prop;
        this.sortDesc = event.order === 'descending';
        await this.getTechnicalReasons();
    },
      handleEdit(index, technicalReason) {
        this.dialogTechnicalReason = cloneDeep(technicalReason);
        this.dialogVisible = true;
      },
      // eslint-disable-next-line no-unused-vars
      handleCreate(index, technicalReason) {
        this.dialogTechnicalReason = {
          id: null, name: null, code: null, parentId: null
        };
        this.dialogVisible = true;
      },
      handleDelete(index, technicalReason) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;
        const res = await technicalReasonsApi.deleteTechnicalReason(technicalReason.id);
          if (!res.data.succeeded) {
            this.$alert(res.data.resultMessage, 'Ошибка', {
              confirmButtonText: 'OK',
              type: 'warning',
            });
          } else {
            await this.getTechnicalReasons();
          }
        this.loading = false;
      });
      },
    toggleNodes() {
      const expandNodes = [];
      const treeKeys = Object.keys(this.$refs.table.store.$data.states.treeData);
      treeKeys.forEach((i) => {
        expandNodes.push(this.$refs.table.store.$data.states.treeData[i].expanded);
      });
      if (expandNodes.includes(false)) {
        this.technicalReasons.forEach((tr) => { this.$refs.table.toggleRowExpansion(tr, true); });
      } else {
        this.technicalReasons.forEach((tr) => { this.$refs.table.toggleRowExpansion(tr, false); });
      }
    }
  }
};
</script>

<style scoped>
.total_items {
   float: right;
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
</style>
