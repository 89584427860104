import axios from 'axios';

export default {
  getTechnicalReasonsWithPagination(pageNumber, pageSize, nameFilter, sortField, sortDesc) {
    return axios.get('/api/TechnicalReasons', {
      params: {
        pageNumber, pageSize, nameFilter, sortField, sortDesc
      }
    });
  },
  getParentTechnicalReasons() { return axios.get('/api/TechnicalReasons/GetParentTechnicalReasons'); },
  addTechnicalReason: (technicalReason) => axios.post('/api/TechnicalReasons', technicalReason),
  updateTechnicalReason: (technicalReason) => axios.put(`/api/TechnicalReasons/${technicalReason.id}`, technicalReason),
  deleteTechnicalReason: (id) => axios.delete(`/api/TechnicalReasons/${id}`), 
};
