<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="40%"
     class="nsi-technical-reason"
    :close-on-click-modal="false"
  >
    <div slot="title">
        Технические причины
        <el-button class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
          <SaveIcon /> Сохранить
        </el-button>
    </div>
    <el-form :rules="rules" v-loading="loading" ref="technicalReasonForm" :model="technicalReason" label-width="12rem">
      <el-form-item prop="name" label="Наименование:">
        <el-input v-model="technicalReason.name"></el-input>
      </el-form-item>
      <el-form-item prop="code" label="Код:">
        <el-input v-model="technicalReason.code"></el-input>
      </el-form-item>

      <el-form-item prop="parentId" label="Родительская запись:">
        <el-select v-model="technicalReason.parentId" clearable>
          <el-option
            v-for="item in parents"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="actual" label="Признак актуальности:">
        <el-checkbox v-model="technicalReason.actual"/>
      </el-form-item>
      <el-form-item prop="mRid" label="mRid:">
        <el-input v-model="technicalReason.mRid"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import technicalReasonsApi from '@/api/nsi/technicalReasons';
import SaveIcon from '@/assets/icons/save.svg';
import validationRules from '@/mixins/validationRules';

export default {
  name: 'TechnicalReasonModal',
  props: ['value', 'technicalReason'],
  mixins: [validationRules],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  components: { SaveIcon },
  data() {
    const parentValidation = (rule, value, callback) => {
      if (this.technicalReason.id
        && this.technicalReason.parentId
        && this.technicalReason.id === this.technicalReason.parentId) {
        return callback(new Error('Запись не может быть родительской по отношению к себе'));
      } else {
        return callback();
      }
    };

    return {
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { max: 200, message: 'До 200 символов', trigger: 'blur' }
        ],
        code: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { max: 200, message: 'До 200 символов', trigger: 'blur' }
        ],
        parentId: [
          { validator: parentValidation, trigger: 'blur' }
        ],
        mRid: [ 
          { validator: this.isUUID, trigger: 'blur' }
        ]
      },
      parents: {},
    };
  },
  async mounted() {
    await this.getParents();
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    async getParents() {
      this.loading = true;
      const res = await technicalReasonsApi.getParentTechnicalReasons();
      if (res.data) {
        this.parents = res.data;
      }
      this.loading = false;
    },
    async addTechnicalReason(technicalReason) {
      await technicalReasonsApi.addTechnicalReason(technicalReason);
      this.$emit('on-create');
    },
    async updateTechnicalReason(technicalReason) {
      await technicalReasonsApi.updateTechnicalReason(technicalReason);
      this.$emit('on-update');
    },
    async validateForm() {
      const valid = await this.$refs.technicalReasonForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;

      const request = this.technicalReason.id ? this.updateTechnicalReason : this.addTechnicalReason;
      this.loading = true;
      await request(this.technicalReason);
      this.dialogVisible = false;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
