<template>
  <div>
    <technical-reasons-filter @active-change="filterActive = $event" :filter-model="filterModel" />
    <technical-reasons-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import TechnicalReasonsFilter from '@/views/Nsi/TechnicalReasons/TechnicalReasonsFilter';
import TechnicalReasonsTable from '@/views/Nsi/TechnicalReasons/TechnicalReasonsTable';

export default {
  name: 'TechnicalReasons',
  components: { TechnicalReasonsTable, TechnicalReasonsFilter },
  data() {
    return {
      filterModel: {
        nameFilter: ''
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
