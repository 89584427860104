export default {
    methods: {
        validatePassword(rule, value, callback) {
          const specials = '!@#%*()$?+-=';

          /* if (!value) {
              callback(new Error('Необходимо указать пароль'));
          } */
          if (value === null || value === undefined) {
            callback();
          } else if (value.length < 8) {
              callback(new Error('Минимальная длина пароля 8 символов'));
          } else if (value.length > 30) {
              callback(new Error('Максимальная длина пароля 30 символа'));
          } else if (!/[A-ZА-Я]/.test(value)) {
              callback(new Error('Пароль должен содержать как минимум одну заглавную букву'));
          } else if (!/[a-zа-я]/.test(value)) {
              callback(new Error('Пароль должен содержать как минимум одну строчную букву'));
          } else if (!/[0-9]/.test(value)) {
              callback(new Error('Пароль должен содержать как минимум одну цифру'));
          } else if (!specials.split('').some((t) => value.split('').some((ch) => ch === t))) {
              callback(new Error('Пароль должен содержать как минимум один символ !@#%*()$?+-='));
          }

          callback();
        },
        arrayOneItemRequired(rule, value, callback) {
          if (!value || value.length === 0 || !value[0]) {
            callback(new Error('Поле обязательно для заполнения'));
          }

          callback();
        },
        arrayTwoItemsRequired(rule, value, callback) {
          if (!value || value.length < 2 || !value[0] || !value[1]) {
            callback(new Error('Необходимо выбрать минимум два значения'));
          }

          callback();
        },
        isUUID(rule, value, callback) {
          if (!value) {
            callback();
          }

          let s = `${value}`;

          s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
          if (s === null) {
            callback(new Error('Значение не является UUID'));
          }

          callback();
        },
        isUUIDList(rule, value, callback) {
          if ((!value) || (!value.length) || (value.length === 0)) {
            callback();
          }

          for (let i = 0; i < value.length; i++) { 
            let s = `${value[i]}`;

            s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
            if (s === null) {
              callback(new Error('Значение не является UUID'));
              return;
            }
          }

          callback();
        }
    }
};
